/*
 * @Author: yuhaiyangz@163.com
 * @Date: 2023-02-02 10:27:32
 * @LastEditors: yuhaiyang yuhaiyangz@163.com
 * @LastEditTime: 2024-08-08 10:43:52
 * @Description: footer
 */
import React,{ useState } from 'react'
import { Image,ImageViewer } from 'antd-mobile'
import  "./footer.scss";
import zcxzs from './img/zcxzs.png'
import zskx from './img/zskx.png'
import gn from './img/ga.png'
export default function CCFooter(){
    const [visible, setVisible] = useState<boolean>(false)
    const renderFooter = (_: string, index: number) => {
        return (
          <div style={{color:'#fff',textAlign:'center',marginBottom:'5vw',fontSize:"var(--font-size-14)"}}>
            {
               index === 0?'中诚信指数':'指数科学'
            }
          </div>
        )
    }
    const childRef:React.RefObject<any>= React.createRef()
    return (
        <>
        <div className="ccFooter">
            <div className="box">
                <div className="left">
                    <h2>联系我们</h2>
                    <ul>
                        <li><span>电话：</span><span>010-65288895</span></li>
                        <li><span>邮箱：</span><span>marketing@ccxindices.com</span></li>
                        <li><span>地址：</span><span>中国·北京东城区朝阳门内大街南竹杆胡同2号银河SOHO-B座20621</span></li>
                    </ul>
                </div>
                <div className="right">
                    <div>
                        <Image src={zcxzs} onContainerClick={()=>{setVisible(true);childRef.current.swipeTo(0)}}/>
                        <p>中诚信指数</p>    
                    </div>  
                    <div>
                        <Image src={zskx} onContainerClick={()=>{setVisible(true);childRef.current.swipeTo(1)}}/>
                        <p>指数科学</p>    
                    </div>   
                </div>                
            </div>
            <div className='ba'>
                <p>中诚信指数服务(青岛)有限公司 京ICP备2020045162号-3</p>
                <div>
                    <img src={gn} alt="" />
                    <a href="">京公网安备 11010102005802</a>
                </div>
            </div>
        </div>
        <ImageViewer.Multi
            ref={childRef}
            images={[zcxzs,zskx]}
            visible={visible}
            defaultIndex={1}
            maxZoom={4}
            onClose={() => {
                setVisible(false)
            }}
            renderFooter={renderFooter}
        />
        </>
    )
}
